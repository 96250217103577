import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';


import Layout from "../components/Layout/Layout"
import Seo from "../components/seo";
import PageBanner from "../components/UI/PageBanner";
import PolicyDetails from "../components/PrivacyPolicy/PolicyDetails";



const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
      <div>
        <PageBanner heading="Privacy Policy"></PageBanner>
       <PolicyDetails></PolicyDetails>
      </div>

  </Layout>
)

export default PrivacyPolicy
