import styled from "styled-components"


export const PolicyWrapper = styled.div`
    padding: 100px 0 ;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    p{
        font-family: 'Montserrat',sans-serif;
        font-size: 15px;
        color: #000;
        font-weight: 500;
        width: 100%;
    }
    h5{
        font-family: 'Montserrat',sans-serif;
        font-size: 20px;
        color: #000;
        font-weight: 700;
        width: 100%;
        margin: 20px 0;
    }
    span{
        display:block;
        font-family: 'Montserrat',sans-serif;
        font-size: 15px;
        color: #000;
        font-weight: 500;
        width: 100%;

    }
`
